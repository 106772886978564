<template>
    <div class="main">
        <img style="width:100%;height:100%; position: absolute;" src="@/assets/images/web/ai/bg.jpg"/>
        <div style="position: absolute;">
            <div class="content">
                <div class="text1">Spark AI Lab</div>
                <div>
                    <div class="text2">输入描述生成音乐，面向未来的音乐授权方式</div>
                    <div class="line1"></div>
                </div>
            </div>
            <div class ="inputs">
                <div class="inputs_box">
                    <div class="text3">描述</div>
                    <input class="msg_input" v-on:input="onChange" type="text"  placeholder="想要什么样的音乐" v-model="content" >
                    <div class="msg_input_text2" >{{residue}}</div>
                    <div class="text4"> 时长（秒）</div>
                    <div class="slider_box" >
                        <a-slider class="c_slider" v-model="inputValue" :min="30" :max="60" :step="1" />
                    </div>
                    <div class="number_input_box">
                        <a-input-number class="number_input" v-model="inputValue" :min="30" :max="60" />
                    </div>      
                </div>
            </div>
            <div class = "button" v-if="step==0 || step == 1">
                <div class="btn_box" @click="onclick">
                    <img class="btn" src="@/assets/images/web/ai/1.png"/>
                    <div class="btn_text"  v-if="step==0">生成音乐</div>
                    <div class="btn_text2" v-if="step==0">></div>
                    <div class="btn_text" v-if="step==1" >生成音乐中……{{count}}s</div>

                </div>
                
            </div>
            <div class = "button" v-if="step==2">
                <div class="btn_box" @click="onclick">
                    <img class="btn" src="@/assets/images/web/ai/2.png"/>
                    <div class="btn_text" @click="download">下载音乐</div>

                </div>
                <div class="btn_box2">
                    <div class="btn_text3" @click="repeat">再来一首</div>
                </div>
                <div class="center">
                    <audio controls="controls" :src="music"></audio>
                </div>
            </div>
            <!-- <div class="player">
                <div v-if="step==0" class="center"></div>
                
                <div v-if="step==2" class="center">
                    <audio controls="controls" :src="music"></audio>
                </div>
                <div class="center">
                    <button v-if="step==0 || step == 1">等待生成音乐</button>
                    <button v-if="step==2" @click="download">下载</button> -->
                    <!-- <a href="https://music.163.com/song/media/outer/url?id=65830.mp3" download >xiazai</a> -->
                <!-- </div>
            </div> -->
        </div>
    </div>
</template>
<script>
    import { Slider,InputNumber } from "ant-design-vue";
    export default {
        data() {
            return {
                inputValue :45,
                content:"",
                step:0,
                count :"",
                music:"https://music.163.com/song/media/outer/url?id=65830.mp3",
                tid:"",
                residue:20,
            };
        },
        components: {
            ASlider: Slider,
            AInputNumber: InputNumber,
        },
        methods: {
            repeat(){
                this.step = 0;
                this.content = "";
                this.count = "";
                this.residue = 20;
            },
            onChange(){
                console.log(this.content,(this.content.length));
                if(this.content.length > 20){
                    this.content = this.content.slice(0,20)
                }
                this.residue = 20-this.content.length;
            },
            onclick() {
                if (this.content==""){
                    alert("请输入描述信息！");
                    return;
                }
                this.step = 1;
                this.count = 30;
                var params = {"text":this.content,"duration":this.inputValue}
                return new Promise(resolve => {
                    this.$axios.TTMRecordTrack(params).then(res => {
                        const data = res.data;
                        console.log(data);
                        if (data.code == 0){
                            this.tid = data.data.data.tasks[0].task_id;
                            
                            this.countdown();
                            resolve(data.data);
                        }else{
                            this.step = 0;
                            alert("请求生成失败!");
                        }
                        
                    })
                })
            },
            checkstatus(){
                var that = this;
                var params = {"task_id":this.tid};
                new Promise(resolve => {
                    this.$axios.TrackStatus(params).then(res => {
                        const data = res.data;
                        console.log(data);
                        if (data.data.task_status_code == 2){
                            that.count = "";
                            that.music = data.data.url;
                            that.step = 2;
                        }
                        resolve(data);
                    })
                })
            },
            download(){
                // window.open(this.music, '_self');
                window.location.href = this.music;
            },
            countdown(){
                var id = setInterval(frame, 1000);
                var that = this;
                function frame() {
                    console.log(that.count);
                    
                    if(that.step == 2){
                        clearInterval(id);
                    }
                    else {
                        that.count--; 
                    }
                    if (that.count % 3 ==  0) {
                        that.checkstatus();
                    } 
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.main{
    min-height: 670px;
}
.content{
    font-size: 25px;
    margin-left: 150px;
    margin-top: 140px; 
    width: 600px;
    display: inline-block;
    height: 80px;
}
.text1{
    color: white;
    font-size:60px;
}
.text2{
    color:white;
    font-size: 30px;
}

.line1{
    background-color: #FF7A12;
    margin-top:10px;
    width: 100%;
    height: 8px;
}

.button{
    // margin-left: 50px;
    margin-top: 190px; 
    display: inline-block;
    height: 80px;
    width:100%;
}
.btn_box{
    width:280px;
    height:84px;
    margin-left: 45%;
}
.btn_box2{
    width:280px;
    height:24px;
    margin-left: 45%;
}
.btn_text3{
    position: absolute;
    color:white;
    font-size: 15px;
    text-align: center;
    width: 280px;
    text-decoration:underline
    // margin-top:20px;
    // margin-left:235px;
}
.btn{
    position: absolute;
    // border-radius:5px;
    width: 280px;
    
    
}
.btn_text{
    position: absolute;
    color:white;
    font-size: 25px;
    margin-top:20px;
    // margin-left:92px;
    text-align:center;
    width:280px;
}
.btn_text2{
    position: absolute;
    color:white;
    font-size: 25px;
    margin-top:20px;
    margin-left:235px;
}
.player{
    /* display: inline-block; */
    /* margin-left: 250px; */
    width:70%;
}
.center{
    // margin: 0 auto; 
    margin-left: 44%;
    text-align: center;
    width:300px;
    margin-top: 20px;
}
.inputs{
    /* font-size: 25px; */
    margin-left: 150px;
    margin-top: 100px; 
    display: inline-block;
    height: 100px;
}
.inputs_box{
    position: absolute;
    top:120px;
    width: 365px;
}
.text3{
    position: absolute;
    color:white;
    top:20px;
    font-size: 20px;
}
.msg_input{
    top: 60px;
    width: 362px;
    border-radius: 5px;
    position: absolute;
    border:none;
}
.msg_input_text2{
    top : 61px;
    left: 330px;
    color:#BFBFBF;
    position: absolute;
}
.text4{
    position: absolute;
    color:white;
    top:100px;
    font-size: 20px;
}

.slider_box{
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 40px;
    vertical-align:top;
    top: 160px;
}
.number_input_box{
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 40px;
    vertical-align:top;
    top: 160px;
    left : 100px;
}
.number_input{
    margin-left: 16px;
    border-radius: 4px;
}
input::-webkit-input-placeholder{

  color:#BFBFBF;

}
.c_slider{
   /deep/ .ant-slider-rail{border-radius: 2px;}
   /deep/ .ant-slider-track{background-color: #FF7A12; border-radius: 2px;}
   /deep/ .ant-slider-handle{border-color: #FF7A12; }
}

</style>